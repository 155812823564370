import React, { useState }  from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Api } from '../api'

const styles = require('../styles/subscribe.module.css')


const Subscribe: React.FC = () => {
    const [email,setEmail] = useState("");
    const [submitted,setSubmitted] = useState(false);
    const [submissionComplete,setSubmissionComplete] = useState(false);
    const [submissionResultMessage,setsubmissionResultMessage] = useState('');

    function submit():void {
        setSubmitted(true);
        Api.subscribeNewsletter(email).then( r => {
            if(r.ok) {
                setsubmissionResultMessage('Thanks for signing up!');
            }
            else{
                setsubmissionResultMessage('There was a problem with your signup, please try again later.');
            }
            setSubmissionComplete(true);
        })
    }

    function emailChanged(e: React.ChangeEvent<HTMLInputElement> ):void {
        setEmail(e.target.value);
    }

    return (
        <div className={styles.subscribeBox}>
        <h1 className={styles.subscribeHeader}>Subscribe</h1>
        <p hidden={submitted} className={styles.subscribeSubtext}>Sign up with your email address to receive news and updates.</p>
        <p hidden={!submissionComplete} className={styles.subscribeSubtext}>{submissionResultMessage}</p>
        <form >
            <div className={styles.emailInput}>
                <TextField disabled={submitted} onChange={emailChanged} value={email} variant="filled" label="Email Address" style={{background:"white", width:"100%", margin:"0px auto"}}/>
            </div>
            <Button disabled={submitted} onClick={submit} style={{background:"black",color:"white", padding:"0px auto", width:"100px", height:"60px"}}>Sign up</Button>
        </form>
    <p className={styles.subscribeDisclaimer}>We respect your privacy and promise to never share, sell, trade, reveal, publicize or market your information to any third party.</p>

</div>
)
}

export default Subscribe;