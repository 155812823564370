import { string } from "prop-types";
import { responsiveFontSizes } from "@material-ui/core";
import { resolve } from "dns";

export interface IContactUsData {
    emailAddr:string;
    firstName:string;
    lastName:string;
    phoneNumber:string;
    message:string;
}
export interface IResponse {
    ok:boolean;
    errorMessage: '';
}
export class Api {
    static get apiUrl():string {
        return process.env.API_URL || 'https://prod.api.parktogive.com/v1';
    }

    static get contactUsUrl():string {
        return Api.apiUrl + "/contactUs";
    }

    static get subscribeNewsletterUrl():string {
        return Api.apiUrl + "/subscribeNewsletter";
    }
    static subscribeNewsletter(email:string):Promise<IResponse> {
       let data = {emailAddr: email};
       
       return Api.postJson(Api.subscribeNewsletterUrl,data);
    }

    static contactUs(data:IContactUsData):Promise<IResponse> {
        return Api.postJson(Api.contactUsUrl,data);
     }
 
    private static postJson(url:string, data:any):Promise<IResponse> {
        return new Promise( resolve => { 
            fetch(url,{
                method: 'POST', 
                body: JSON.stringify(data), 
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(resp => {
                if(resp.ok) {
                    resolve({ok: true, errorMessage:''});
                }
            })
        
        });
    }


}